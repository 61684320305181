@font-face {
  font-family: "RuneScape";
  src: url("../public/runescape.ttf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
button {
  font-family: "RuneScape";
  font-size: large;
}

#root {
  height: 100vh;
}

.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  text-align: center;
  border: 1px solid #6e604e;
  outline: 1px solid #2d2a22;
  background-image: url(/public/bg2.jpg);
  background-repeat: repeat-y;
  background-position: center top;
  background-color: black;
}

.home-page-container {
  margin-inline: auto;
  position: relative;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6e604e;
  outline: 1px solid #2d2a22;
}

.map-container {
  position: relative;
  z-index: 0;
}

.dialog-title {
  font-size: xx-large;
  color: yellow;
}

.dialog-message {
  font-size: 20px;
  margin-bottom: 10px;
}

.rotate-text {
  position: fixed;
  transform: rotateY(0deg) rotate(-45deg);
  color: yellow;
  top: -10px;
  left: -10px;
}

.submit-guess-button {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: xxx-large;
  z-index: 1000;
  font-family: "RuneScape";
  margin-right: 5px;
}

.guess-result {
  position: fixed;
  display: inline-block;
  text-align: center;
  padding: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 5px;
  border: 1px solid #6e604e;
  outline: 1px solid #2d2a22;
  box-shadow: -5px -5px 15px black;
  z-index: 2;
}

.osrs-button {
  margin-top: 5px;
  padding: 5px 10px 5px 10px;
  border: 1px solid #6e604e;
  outline: 1px solid #2d2a22;
  background: #554c41;
  border-radius: 5px;
  color: orange;
  cursor: pointer;
}

.osrs-button:hover {
  color: white;
}

.round-scores {
  margin-top: 10px;
  margin-bottom: 10px;
}

.todays-total-score {
  font-size: 20px;
  margin-bottom: 2px;
}

ul > li {
  display: inline-block;
}

li {
  margin: 2px;
}

.score-list {
  padding: 5px;
  border: 1px solid #6e604e;
  outline: 1px solid #2d2a22;
  background: #554c41;
  border-radius: 5px;
}

.osrs-background {
  color: orange;
  background: #4e4538;
}

#green {
  color: #0ead0d;
}

#light-green {
  color: rgb(169, 228, 51);
}

#yellow {
  color: yellow;
}

#red {
  color: red;
}

.game-over {
  position: fixed;
  display: inline-block;
  text-align: center;
  padding: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 5px;
  border: 1px solid #6e604e;
  outline: 1px solid #2d2a22;
  box-shadow: -5px -5px 15px black;
  z-index: 2;
}

.countdown-container {
  position: fixed;
  display: block;
  left: 50%;
  top: 0;
  width: 100%;
  transform: translate(-50%, 0);
  box-shadow: 0px 0px 10px black;
  z-index: 2;
  height: 100vh;
  align-content: center;
  backdrop-filter: blur(10px);
  cursor: pointer;
}

.x-button {
  position: fixed;
  display: block;
  top: 0;
  color: orange;
  right: 0;
  font-size: xxx-large;
  margin-top: 10px;
  margin-right: 10px;
}

.countdown-container > img {
  display: block;
  margin: auto;
}

.countdown {
  border: 1px solid #6e604e;
  outline: 1px solid #2d2a22;
  background: #554c41;
  border-radius: 5px;
  color: orange;
  text-align: center;
  width: 25%;
  margin: 0 auto;
  padding: 10px;
}

.hidden-location {
  position: fixed;
  display: block;
  height: 20vh;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px black;
  z-index: 2;
  cursor: pointer;
}

.hidden-location-image {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
